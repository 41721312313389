import React, { useState } from 'react';
//import Login from './Home/Login';
import Dashboard from './Home/Dashboard';
// import SignIn from './Home/NewLogin'
import { TempLogin } from './Home/TempLogin';

const App = () => {
  
  const [authenticated, setAuthenticated] = useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [organizationImage, setOrganizationImage] = useState("");
  const[collectRequestTimeOut,setCollectRequestTimeOut]=useState("");

  const handleLogin = (organizationId,custNumber, organizationImage,collectRequestTimeOut) => {
    // Assuming successful authentication, set customer details and change the authentication state.
    setOrganizationId(organizationId);
    setCustomerNumber(custNumber);
    setOrganizationImage(organizationImage);
    setCollectRequestTimeOut(collectRequestTimeOut);
    setAuthenticated(true);
  };

  return (
    <div>
      {!authenticated ? (
        // <SignIn onLogin={handleLogin} />
        <TempLogin onLogin={handleLogin}/>
      ) : (
        <Dashboard organizationId={organizationId} customerNumber={customerNumber} organizationImage={organizationImage} collectRequestTimeOut={collectRequestTimeOut} />
      )}
    </div>
  );
};

export default App;
