export const apiCall = {

  sendotpforcustomerlogin: "customer/sendotpforcustomerlogin",
  verifyotpforcustomerlogin: "customer/verifyotpforcustomerlogin",
  getloannumberbycustnumber:"customer/getallocationsbycustnumber",
  sendpaymentlink:"dpdtest/sendpaymentlink",
  getorganizationadataByShortCode:"userlogin/getorganizationadata",

  //Lambda Api's

  // Bulk Upload

  // QA Base Url
  bulkUploadBaseUrl:
    "https://9ylmyoqrai.execute-api.ap-south-1.amazonaws.com/default/bulk_upload",

  payrollUploadBaseUrl:
    "https://16ydqqpwz7.execute-api.ap-south-1.amazonaws.com/default/payroll",

  // Prod Base Url
  // bulkUploadBaseUrl :"https://65vveckfzb.execute-api.ap-south-1.amazonaws.com/default/bulk_upload",

  // payrollUploadBaseUrl:
  //   "https://k0ndi2no21.execute-api.ap-south-1.amazonaws.com/default/clu_pay_roll",
};
