import swal from "sweetalert";
import { apiCall } from "../rest/restApi";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import * as moduleApi from "../rest/moduleApi";
import "./style2.css";
import { Col, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import clucLogo from "../images/circlelogo.png";

const Dashboard = ({ organizationId, customerNumber, organizationImage,collectRequestTimeOut}) => {
  const [loanNumbers, setLoanNumbers] = useState(null);
  const [isLoanDetails, setIsLoanDetails] = useState(false);
  const [selectedLoanNumber, setSelectedLoanNumber] = useState({});
  const [isPaymentLinkSent, setIsPaymentLinkSent] = useState(false);

  useEffect(() => {
    const getLoanNumbers = () => {
      const payLoad = {
        customerNumber: customerNumber,
        organizationId: organizationId,
      };

      moduleApi
        .postData(apiCall.getloannumberbycustnumber, payLoad)
        .then((response) => {
          const result = response?.map((item, index) => ({
            value: item.loanNumber,
            label: item.loanNumber,
            dueAmount: item.dueAmount,
            registrationNumber: item.registrationNumber,
            organizationId: item.organizationId,
            allocationId: item.allocationId,
            customerName: item.customerName,
            index: index,
          }));
          setLoanNumbers(result);
        });
    };
    getLoanNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendPaymentLink = () => {
    const payLoad = {
      amount: getPaisa(selectedLoanNumber?.dueAmount),
      message_channel: "SMS",
      visitId: 0,
      customerName: selectedLoanNumber?.customerName,
      customerPhoneNumber: customerNumber,
      organizationId: selectedLoanNumber?.organizationId,
      userId: "",
      loan_number: selectedLoanNumber?.loanNumber,
      allocationId: selectedLoanNumber?.allocationId,
    };

    moduleApi.postData(apiCall.sendpaymentlink, payLoad).then((response) => {
      if (response?.message) {
        return swal(response?.message, {
          icon: "error",
          dangerMode: true,
        });
      } else if (response) {
        setIsPaymentLinkSent(true);
        return swal("Link Sent Successfully", {
          icon: "success",
          dangerMode: false,
        });
      } else {
        return swal("Some thing went wrong", {
          icon: "error",
          dangerMode: true,
        });
      }
    });
  };

  const getPaisa = (value) => {
    return value * 100;
  };

  return (
    // <div className="card-container">
    //   <div className="card">
    //     <h2>Customer Details</h2>

    //     <div className="data-values">
    //       <div style={{ display: "flex", flexDirection: "column", marginBottom:'5px' }}>
    //         <label style={{ fontWeight: "bold" }}>Loan Number</label>
    //         <div
    //           style={{
    //             float: "right",
    //             width: "280px",
    //             marginRight: "5px",
    //             height: "38px",
    //             // marginLeft: "5px",
    //             borderRadius: "3px",
    //             borderWidth: "1px",
    //             boxShadow: "1px 1px 2px #797979",
    //           }}
    //         >
    //           <Select
    //             id="loanNumberDropDown"
    //             placeholder="Select Loan Number"
    //             onChange={(value) => {
    //               setSelectedLoanNumber(value);
    //               setIsLoanDetails(true);
    //             }}
    //             options={loanNumbers}
    //           />
    //         </div>
    //       </div>

    //       <Row>
    //         <Col>
    //           <table>
    //             <tr>
    //               <td
    //                 style={{
    //                   fontWeight: "500",
    //                   textAlign: "left",
    //                 }}
    //               >
    //                 Customer Number
    //               </td>
    //               <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
    //               <td
    //                 style={{
    //                   fontWeight: "bold",
    //                   textAlign: "left",
    //                 }}
    //               >
    //                 {customerNumber}
    //               </td>
    //             </tr>
    //             {isLoanDetails === true && (
    //               <tr>
    //                 <td
    //                   style={{
    //                     fontWeight: "500",
    //                     textAlign: "left",
    //                   }}
    //                 >
    //                   Customer Name
    //                 </td>
    //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
    //                 <td
    //                   style={{
    //                     fontWeight: "bold",
    //                     textAlign: "left",
    //                   }}
    //                 >
    //                   {selectedLoanNumber?.customerName}
    //                 </td>
    //               </tr>
    //             )}
    //             {isLoanDetails === true && (
    //               <tr>
    //                 <td
    //                   style={{
    //                     fontWeight: "500",
    //                     textAlign: "left",
    //                     verticalAlign: "top",
    //                   }}
    //                 >
    //                   Registration Number
    //                 </td>
    //                 <td style={{ verticalAlign: "top" }}>
    //                   &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
    //                 </td>
    //                 <td
    //                   style={{
    //                     fontWeight: "bold",
    //                     textAlign: "left",
    //                   }}
    //                 >
    //                   {selectedLoanNumber?.registrationNumber}
    //                 </td>
    //               </tr>
    //             )}
    //             {isLoanDetails === true && (
    //               <tr>
    //                 <td
    //                   style={{
    //                     fontWeight: "500",
    //                     textAlign: "left",
    //                   }}
    //                 >
    //                   Due Amount
    //                 </td>
    //                 <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
    //                 <td
    //                   style={{
    //                     fontWeight: "bold",
    //                     textAlign: "left",
    //                   }}
    //                 >
    //                   {selectedLoanNumber?.dueAmount===0?"There is no due for this loan No in the current month":selectedLoanNumber?.dueAmount}

    //                 </td>
    //               </tr>
    //             )}
    //           </table>
    //         </Col>
    //       </Row>
    //     </div>
    //     {isLoanDetails === true &&selectedLoanNumber?.dueAmount!==0 && (
    //       <div>
    //         <button className="action-button" onClick={sendPaymentLink}>
    //           Request Payment Link
    //         </button>
    //       </div>
    //     )}
    //   </div>
    // </div>
    <div className="card-container">
      <div className="card">
        <div style={{ textAlignLast: "center", marginBottom: "10px" }}>
          <img
            src={organizationImage ? organizationImage : clucLogo}
            alt=""
            style={{ height: "90px", width: "90px", borderRadius:'50%' }}
          />
        </div>
        <h3
          style={{ color: "#1560bd", fontWeight: "bold", fontSize: "larger" }}
        >
          Customer Details
        </h3>
        <hr />

        <div className="data-values">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "5px",
            }}
          >
            <label style={{ fontWeight: "bold" }}>Loan Number</label>
            <div
              style={{
                // float: "right",
                // width: "280px",
                // marginRight: "5px",
                height: "38px",
                // marginLeft: "5px",
                borderRadius: "3px",
                borderWidth: "1px",
                boxShadow: "1px 1px 2px #797979",
              }}
            >
              <Select
                id="loanNumberDropDown"
                placeholder="Select Loan Number"
                onChange={(value) => {
                  setSelectedLoanNumber(value);
                  setIsPaymentLinkSent(false);
                  setIsLoanDetails(true);
                }}
                options={loanNumbers}
              />
            </div>
          </div>

          <Row style={{ marginTop: "20px" }}>
            <Col>
              <table>
                <tr>
                  <td
                    style={{
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    Customer Number
                  </td>
                  <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    {customerNumber}
                  </td>
                </tr>
                {isLoanDetails === true && (
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Customer Name
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {selectedLoanNumber?.customerName}
                    </td>
                  </tr>
                )}
                {isLoanDetails === true && (
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        verticalAlign: "top",
                      }}
                    >
                      Registration Number
                    </td>
                    <td style={{ verticalAlign: "top" }}>
                      &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{" "}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {selectedLoanNumber?.registrationNumber}
                    </td>
                  </tr>
                )}
                {isLoanDetails === true && (
                  <tr>
                    <td
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Due Amount
                    </td>
                    <td>&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {selectedLoanNumber?.dueAmount === 0
                        ? "There is no due for this loan No in the current month"
                        : selectedLoanNumber?.dueAmount}
                    </td>
                  </tr>
                )}
              </table>
            </Col>
          </Row>
        </div>
        {isPaymentLinkSent && (
          <div
            style={{
              marginTop: "50px",
              color: "red",
            }}
          >
            <span>Payment link will expire in {collectRequestTimeOut/60} mins</span>
          </div>
        )}
        {!isPaymentLinkSent &&
          isLoanDetails === true &&
          selectedLoanNumber?.dueAmount >=1 && (
            <div
            //   style={{
            // marginTop:"70px"
            //   }}
            >
              {/* <button className="action-button" 
           onClick={sendPaymentLink}> */}
              <Button
                onClick={sendPaymentLink}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Request Payment Link
              </Button>
              {/* </button> */}
            </div>
          )}
      </div>
    </div>
  );
};

export default Dashboard;
