import React, { useState, useEffect } from "react";
//import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
//import Link from '@mui/material/Link';
//import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import swal from "sweetalert";
import clucLogo from "../images/circlelogo.png";
import "./style.css";

const defaultTheme = createTheme();

export const TempLogin = ({ onLogin }) => {
  const [otpSent, setOtpSent] = useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const [orgDetails, setOrgDetails] = useState({});
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(180);
  const [resendOtp, setResendOtp] = useState(false);

  useEffect(() => {
    var url = window.location.pathname;
    // var url2 = url.replace("/", "");
    console.log("url", url);

    const getOrgDetails = () => {
      moduleApi
        .getData(apiCall.getorganizationadataByShortCode + url)
        .then((response) => {
          console.log(response);

          const result = {
            organizationId: response?.autoFinanceOrganization?.organizationId,
            organizationName:
              response?.autoFinanceOrganization?.organizationName,
            organizationImage:
              response?.autoFinanceOrganization?.organizationImage,
            collectRequestTimeOut:
              response?.autoFinanceOrganization?.collectRequestTimeOut,
          };
          setOrgDetails(result);
        });
    };

    // eslint-disable-next-line

    getOrgDetails();
  }, []);

  useEffect(() => {
    let interval;
    // If OTP is sent, start the timer
    if (otpSent) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [otpSent]);

  useEffect(() => {
    let interval;
    // Reset the timer and stop it when it reaches 0
    if (timer === 0) {
      setResendOtp(true);

      clearInterval(interval);
    }
  }, [timer]);

  const handleSubmit = () => {
    const payLoad = {
      customerNumber: customerNumber,
      otp: otp,
      organizationId: orgDetails.organizationId,
    };

    moduleApi
      .postData(apiCall.verifyotpforcustomerlogin, payLoad)
      .then((response) => {
        if (response?.Message === "Otp Verified Successfully") {
          onLogin(
            orgDetails.organizationId,
            customerNumber,
            orgDetails?.organizationImage,
            orgDetails.collectRequestTimeOut
          );
        } else if (response?.message) {
          return swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          return swal("Some thing went wrong", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    const payLoad = {
      customerNumber: customerNumber,
      organizationId: orgDetails.organizationId,
    };

    moduleApi
      .postData(apiCall.sendotpforcustomerlogin, payLoad)
      .then((response) => {
        if (response?.Message === "Otp Sent Successfully") {
          // toaster('success', 'Successfully Added')
          setOtpSent(true);
          return swal("Otp Sent Successfully", {
            icon: "success",
          });
        } else if (response?.message) {
          // toaster("error", response?.message);
          swal(response?.message, {
            icon: "error",
            dangerMode: true,
          });
        } else {
          return swal("Something Went Wrong, Please Try Later", {
            icon: "error",
            dangerMode: true,
          });
        }
      });
  };

  const handleResendOtp = () => {
    setTimer(180);
    setResendOtp(false);
    handleSendOtp();
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes} Mins : ${seconds < 10 ? "0" : ""}${seconds} secs`;
  };
  return (
    <div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src="images/undraw_remotely_2j6y.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 contents">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <ThemeProvider theme={defaultTheme}>
                    <Container component="main" maxWidth="xs">
                      <CssBaseline />
                      {/* <Box
                    sx={{
                      //   marginTop: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  > */}
                      <div style={{ textAlignLast: "center" }}>
                        <img
                          src={
                            orgDetails?.organizationImage
                              ? orgDetails?.organizationImage
                              : clucLogo
                          }
                          alt=""
                          style={{
                            height: "90px",
                            width: "90px",
                            borderRadius: "50%",
                          }}
                        />
                        <Typography
                          component="h2"
                          variant="h5"
                          style={{
                            marginBottom: "5px",
                            marginTop: "5px",
                            textAlign: "center",
                            color: "#4108df",
                            fontWeight: "bold",
                          }}
                        >
                          Welcome to{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {orgDetails?.organizationName
                              ? orgDetails?.organizationName
                              : "Clu"}
                          </span>
                        </Typography>
                        <Typography component="h1" variant="h5" style={{}}>
                          Sign in
                        </Typography>
                      </div>
                      <Box component="form" onSubmit={handleSendOtp} noValidate sx={{ mt: 1 }}>
                        {/* <label htmlFor="customerNumber">Customer Number</label> */}
                        <TextField
                          //   style={{backgroundColor:'#f8fafb'}}
                          margin="normal"
                          required
                          fullWidth
                          id="customerNumber"
                          label="Customer Registered Mobile"
                          name="customerNumber"
                          disabled={otpSent}
                          //   autoFocus
                          onChange={(e) => {
                            setCustomerNumber(e.target.value);
                           
                          }}
                        />

                        <div>
                          {!otpSent && (
                            <div>
                              <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                // onClick={handleSendOtp}
                                sx={{ mt: 3, mb: 2 }}
                              >
                                Request OTP
                              </Button>
                            </div>
                          )}

                          {otpSent && (
                            <div className="otp-container">
                              {/* <label htmlFor="otp">OTP</label> */}
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="otp"
                                label="OTP"
                                name="otp"
                                onChange={(e) => setOtp(e.target.value)}
                              />
                              {resendOtp ? (
                                <span
                                  className="resend-otp-button"
                                  onClick={handleResendOtp}
                                >
                                  Resend OTP
                                </span>
                              ) : (
                                <div>
                                  <span className="resend-otp-timer">
                                    Resend OTP in {formatTime(timer)}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <Button
                          onClick={handleSubmit}
                          fullWidth
                          variant="contained"
                          disabled={!otpSent}
                          // style={{backgroundColor:'#0ba90b'}}
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Sign In
                        </Button>
                      </Box>
                      {/* </Box> */}
                    </Container>
                  </ThemeProvider>
                  {/* <div className="mb-4">
                    <h3>Sign In</h3>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sapiente sit aut eos
                      consectetur adipisicing.
                    </p>
                  </div>
                  <form action="#" method="post">
                    <div className="form-group first">
                      <label htmlFor="username">Username</label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                      />
                    </div>
                    <div className="form-group last mb-4">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                      />
                    </div>
                    <div className="d-flex mb-5 align-items-center">
                      <label className="control control--checkbox mb-0">
                        <span className="caption">Remember me</span>
                        <input type="checkbox" defaultChecked="checked" />
                        <div className="control__indicator" />
                      </label>
                      <span className="ml-auto">
                        <a href="#" className="forgot-pass">
                          Forgot Password
                        </a>
                      </span>
                    </div>
                    <input
                      type="submit"
                      defaultValue="Log In"
                      className="btn btn-block btn-primary"
                    />
                    <span className="d-block text-left my-4 text-muted">
                      — or login with —
                    </span>
                    <div className="social-login">
                      <a href="#" className="facebook">
                        <span className="icon-facebook mr-3" />
                      </a>
                      <a href="#" className="twitter">
                        <span className="icon-twitter mr-3" />
                      </a>
                      <a href="#" className="google">
                        <span className="icon-google mr-3" />
                      </a>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
