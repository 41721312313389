// import { getTokenInLocalStorage } from "../services/AuthService";
import {
  handleResponse,
  handleError,
  //handleResponseForExcelDownload,
} from "./apiUtils";
// var userLoginDetails = getTokenInLocalStorage();

// console.log("login", userLoginDetails);
//DEMO QA
// const BASE_PATH = "https://qademo.clucloud.com/api/";
// const EMAIL_BASE_PATH = "https://qademo.clucloud.com/api/";

//QA
// const BASE_PATH = "https://qa.clucloud.com/api/";

//prod

const BASE_PATH = "https://clu.clucloud.com/api/";


// New Prod

// const BASE_PATH =
//   userLoginDetails === null ||
//   userLoginDetails?.autoFinanceUser?.roleName !== "SERVICE_EXECUTIVE"
//     ? "https://clu.clucloud.com/api/"
//     : "http://15.207.223.30:8014/api/";
// const EMAIL_BASE_PATH = "https://clu.clucloud.com/api/";

const getRequestOptions = (method, body) => {
  let options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
      apikey: "834563271cd84fc12481fcf4ad2841a8",
    },
  };
  if (body) options = { ...options, body: JSON.stringify(body) };
  return options;
};



export function getData(name, queryParams) {
  let url = `${BASE_PATH}${name}${queryParams ? queryParams : ""}`;
  return fetch(url, getRequestOptions("GET"))
    .then(handleResponse)
    .catch(handleError);
}

export function postData(name, body) {
  return fetch(`${BASE_PATH}${name}`, getRequestOptions("POST", body))
    .then(handleResponse)
    .catch(handleError);
}

const authHeader = () => {
  let user = JSON.parse(localStorage.getItem("userDetails"));
  if (user !== null) {
    if (user && user.jwtToken) {
      return { Authorization: "Bearer " + user.jwtToken };
    } else {
      return {};
    }
  }
};

export function withPage(api, page, size) {
  return api + "?page=" + page + "&size=" + size;
}
export function withPagination(api, isPageable, page, size) {
  return api + isPageable + "?page=" + page + "&size=" + size;
}

// const getRequestOptionsForBulkUpload = (method, body) => {
//   let options = {
//     method: method,
//     headers: {
//       "Content-Type":
//         // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//         "text/csv",
//       // "fileName":fileName
//     },
//   };
//   if (body) options = { ...options, body: body };
//   return options;
// };

// const getRequestOptionsForPayroll = (method, body) => {
//   let options = {
//     method: method,
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };
//   if (body) options = { ...options, body: JSON.stringify(body) };
//   return options;
// };

// const getRequestOptionsForExcel = (method, file, myJSON) => {
//   var formData = new FormData();
//   formData.append("file", file);
//   formData.append("excelJson", myJSON);
//   let options = {
//     method: method,
//     headers: { ...authHeader() },
//   };
//   if (file) options = { ...options, body: formData };
//   return options;
// };


/*
 * Generic method to get the data for all the list get endpoints
 */

// export function getUserData(name) {
//   let url = `${BASE_PATH}${name}`;
//   return fetch(url, getRequestOptions("GET"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function getDataById(name, queryParams, queryParams2) {
//   let url = `${name}?organizationId=${queryParams}&action=${queryParams2}`;
//   return fetch(url, getRequestOptions("GET"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// //Bulk Upload
// export function getDataByOrgName(name, orgId, action, fileName) {
//   let url = `${name}&organizationId=${orgId}&action=${action}&fileName=${fileName}`;
//   return fetch(url, getRequestOptions("POST"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function postDataByOrgIdForPayroll(name, body) {
//   //let userId=`${BASE_PATH}${name}?id=${id}`;
//   return fetch(`${name}`, getRequestOptionsForPayroll("POST", body))
//     .then(handleResponse)
//     .catch(handleError);
// }

// //Download Bulk Upload File
// export function getDownloadedFilePreSignedUrl(name, orgId, fileName) {
//   let url = `${name}&organizationId=${orgId}&fileName=${fileName}`;
//   return fetch(url, getRequestOptions("POST"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function getDownloadedFile(name) {
//   let url = `${name}`;

//   return fetch(url, getRequestOptionsForBulkUpload("GET"))
//     .then(handleResponseForExcelDownload)
//     .catch(handleError);
// }

// export function getUpoadedList(
//   name,
//   action,
//   orgId,
//   endDate,
//   limit,
//   pageSize,
//   startDate
// ) {
//   let url = `${name}?action=${action}&organizationId=${orgId}&endDate=${endDate}&limit=${limit}&pageSize=${pageSize}&startDate=${startDate}`;
//   return fetch(url, getRequestOptions("POST"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function getUserImage(name) {
//   let url = `${name}`;
//   return fetch(url, getRequestOptions("GET")).catch(handleError);
// }

// export function getDataWithQueryParam(name, queryParams) {
//   let url = `${BASE_PATH}${name}/${queryParams ? queryParams : ""}`;

//   return fetch(url, getRequestOptions("GET"))
//     .then(handleResponse)
//     .catch(handleError);
// }
// export function getDataQueryParam(name, queryParams) {
//   let url = `${BASE_PATH}${name}?userid=${queryParams}`;

//   return fetch(url, getRequestOptions("GET"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function getDataByID(name, id, quantity) {
//   let url = `${BASE_PATH}${name}${id ? "/" + id : ""}${
//     quantity ? "/" + quantity : ""
//   }`;

//   return fetch(url, getRequestOptions("GET"))
//     .then(handleResponse)
//     .catch(handleError);
// }
// export function getDataByUserID(name, id, quantity, userId) {
//   let url = `${BASE_PATH}${name}${id ? "/" + id : ""}${
//     quantity ? "/" + quantity : ""
//   }${userId ? "/" + userId : ""}`;

//   return fetch(url, getRequestOptions("GET"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function postDataByName(name, id, body) {
//   //let userId=`${BASE_PATH}${name}?id=${id}`;
//   return fetch(`${name}?organizationId=${id}`, getRequestOptions("POST", body))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function postDataByBody(name, body) {
//   //let userId=`${BASE_PATH}${name}?id=${id}`;
//   return fetch(`${name}`, getRequestOptions("POST", body))
//     .then(handleResponse)
//     .catch(handleError);
// }


// export function loginPostData(name, body) {
//   return fetch(`${BASE_PATH}${name}`, getRequestOptions("POST", body))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function postDataById(name, id, body) {
//   //let userId=`${BASE_PATH}${name}?id=${id}`;
//   return fetch(`${BASE_PATH}${name}?id=${id}`, getRequestOptions("POST", body))
//     .then(handleResponse)
//     .catch(handleError);
// }
// export function getDataByIDUsingHr(name, id, quantity) {
//   let url = `${BASE_PATH}${name}${id ? "/" + id : ""}${
//     quantity ? "?requestFor=" + quantity : ""
//   }`;
//   return fetch(url, getRequestOptions("GET"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function putData(name, body) {
//   return fetch(`${BASE_PATH}${name}`, getRequestOptions("PUT", body))
//     .then(handleResponse)
//     .catch(handleError);
// }

//Bulk Upload
// export function putDataByName(name, body) {
//   return fetch(`${name}`, getRequestOptionsForBulkUpload("PUT", body))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function deleteData(name, id) {
//   return fetch(`${BASE_PATH}${name}/${id}`, getRequestOptions("DELETE"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function exportData(name) {
//   return fetch(`${BASE_PATH}${name}`, getRequestOptions("GET"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function getDataForDownload(name) {
//   return fetch(`${name}`, getRequestOptions("GET"))
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function importData(name, file, myJSON) {
//   return fetch(
//     `${BASE_PATH}${name}`,
//     getRequestOptionsForExcel("POST", file, myJSON)
//   )
//     .then(handleResponse)
//     .catch(handleError);
// }


